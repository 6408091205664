import React from 'react';
import styled from 'styled-components';

import GraphicsEl from '../images/graphics.svg';
import media from '../utils/media';

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 6rem);
  align-items: center;
  padding: 1rem 5rem;
  flex: 0.5;
  background-color: #ffffff;

  ${media.tablet`
    padding: 1rem 2rem;
    min-height: calc(80vh - 6rem);
  `}
`;

const Presentation = styled.div`
  ${media.phone`
    display: none;
  `}
`;

const Content = styled.div`
  text-align: center;
  align-self: center;
  flex: 1;
`;

const ActionButton = styled.button`
  background-color: #ff4133;
  color: #ffffff;
  padding: 1.2rem;
  cursor: pointer;
  width: 20rem;
  font-size: 1.7rem;
  text-transform: uppercase;
  border: 0;
  box-shadow: none;
  font-weight: 500;
  margin-top: 5rem;

  ${media.phone`
    margin-top: 4rem;
  `}
`;

const Intro = () => (
  <Container>
    <Presentation>
      <GraphicsEl />
    </Presentation>
    <Content>
      <p>Are you ready to be a new <strong>family</strong> member of Algebra?
      <br />Do you like to work with cutting edge technologies?
      <br />You are looking for new journeys?</p>

      <ActionButton 
        onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>Join Us</ActionButton>
    </Content>
  </Container>
);

export default Intro;
